body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff
}

.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:root {
  --swiper-theme-color: #FF9900!important;
}

.swiper-button-next:after,.swiper-rtl .swiper-button-prev:after{
  content: '>';
  font-size: 24px!important;
  color: #FF9900;
  font-weight: 600;
}

.swiper-button-prev:after,.swiper-rtl .swiper-button-next:after{
  content: '<';
  font-size: 24px!important;
  color: #FF9900;
  font-weight: 600;
}